import { ContentSummaryProps, ContentSummaryVariant } from "./types"

import { Base } from "./variants/Base"
import { BaseWide } from "./variants/BaseWide"
import { Large } from "./variants/Large"
import { ExtraLarge } from "./variants/ExtraLarge"
import { ExtraExtraLarge } from "./variants/ExtraExtraLarge"
import { Hero } from "./variants/Hero"
import { DisappearingThumbnail } from "./variants/DisappearingThumbnail"

export const ContentSummary = (props: ContentSummaryProps) => {
  const { customTitle, image, title, variant } = props
  const variantProps = {
    ...props,
    title: customTitle?.trim() || title,
    image: {
      ...image,
      alt: image?.alt || title,
    },
  }

  switch (variant) {
    case ContentSummaryVariant.Hero:
      return <Hero {...variantProps} />
    case ContentSummaryVariant.Xxl:
      return <ExtraExtraLarge {...variantProps} />
    case ContentSummaryVariant.Xl:
      return <ExtraLarge {...variantProps} />
    case ContentSummaryVariant.Lg:
      return <Large {...variantProps} />
    case ContentSummaryVariant.DisappearingThumbnail:
      return <DisappearingThumbnail {...variantProps} />
    case ContentSummaryVariant.BaseWide:
      return <BaseWide {...variantProps} />
    default:
      return <Base {...variantProps} />
  }
}
