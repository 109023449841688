import { ContentSummaryTag } from "common/components/ContentSummaryTag"
import { Heading } from "common/components/Heading"
import { ImgixImage } from "common/components/ImgixImage"
import { closestCrop } from "modules/editor/utils/closestCrop"
import { ImageAspectRatio } from "modules/editor/types"
import type { ContentSummaryVariantProps } from "common/components/ContentSummary/types"
import clsx from "classnames"

export const ExtraExtraLarge = ({
  title,
  url,
  body,
  byline,
  hideByline,
  contentType,
  imageSizes,
  image,
  tagLabel,
  tagStyle,
  anchorTarget,
  className,
}: ContentSummaryVariantProps) => {
  const crop = closestCrop(ImageAspectRatio.Widescreen, image?.crops || [])
  return (
    <a
      href={url}
      target={anchorTarget}
      className={clsx("group flex w-full flex-col gap-2 sm:flex-row sm:gap-6", className)}
    >
      {image?.src && (
        <div className="relative aspect-video sm:basis-1/2">
          <ImgixImage
            src={image.src}
            alt={image.alt}
            placeholderColor={image.placeholderColor || "#000000"}
            crop={crop}
            sizes={imageSizes}
          />
        </div>
      )}
      <section className="flex basis-1/2 flex-col items-start gap-2">
        <span className="group-hover:text-indigo-600 group-hover:underline">
          <Heading level={3}>
            <span dangerouslySetInnerHTML={{ __html: title || "" }} />
          </Heading>
        </span>
        {<ContentSummaryTag contentType={contentType} tagLabel={tagLabel} tagStyle={tagStyle} />}
        {body && (
          <span className="hidden sm:inline">
            <p className="line-clamp-3 font-serif">{body}</p>
          </span>
        )}
        {byline && !hideByline && <p className="line-clamp-3 text-3xs text-gray-600">{byline}</p>}
      </section>
    </a>
  )
}
