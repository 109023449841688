import type { PropsWithChildren, ReactNode } from "react"
import clsx from "classnames"
import { XCircleIcon } from "@heroicons/react/20/solid"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { MouseEventHandler } from "react"

interface TagProps {
  onClick?: MouseEventHandler<HTMLSpanElement>
  onClose?: MouseEventHandler<HTMLButtonElement>
  icon?: ReactNode
  active?: boolean
}

export const Tag = ({ icon, onClick, onClose, children, active }: PropsWithChildren<TagProps>) => {
  const t = useTranslations("components.tag")

  const withActive = active !== undefined
  const ariaSelected = withActive ? { "aria-selected": active } : {}
  const styleSelected = withActive ? active : true

  const innerContent = (
    <>
      <span className="truncate">{children}</span>
      {icon && !onClose && <div className="-mr-1.5 ml-0.5">{icon}</div>}
    </>
  )

  return (
    <span
      {...ariaSelected}
      className={clsx(
        `inline-flex max-w-full items-center gap-1 truncate rounded-full px-3 py-1 font-sans text-sm  hover:bg-black`,
        {
          "bg-black/70 text-white hover:bg-black": styleSelected,
          "bg-black/10 text-black hover:bg-black/20": !styleSelected,
        },
      )}
    >
      {onClick ? (
        <button onClick={onClick} className="inline-flex gap-1">
          {innerContent}
        </button>
      ) : (
        innerContent
      )}

      {onClose && (
        <button aria-label={t("close")} onClick={onClose} className="-mr-1.5 ml-0.5">
          <XCircleIcon className="h-4 w-4 text-white hover:text-gray-50" />
        </button>
      )}
    </span>
  )
}
