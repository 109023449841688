import { PhotoIcon } from "@heroicons/react/24/solid"

export const ImagePlaceholder = () => (
  <div
    className="flex aspect-square h-full w-full items-center justify-center bg-gray-200"
    data-testid="ImagePlaceholder"
  >
    <PhotoIcon className="w-1/4 text-gray-50" />
  </div>
)
