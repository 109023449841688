import { Crop } from "common/types/graphql"
import { ImageAspectRatio } from "../types"

/**
 * Picks the closest crop to the desired aspect ratio.
 *
 * @param aspectRatio The desired aspect ratio.
 * @param crops The list of possible crops.
 */
export function closestCrop(aspectRatio: ImageAspectRatio, crops: Crop[]) {
  const parts = aspectRatio.split(":").map(Number)
  const ar = parts[0] / parts[1]
  return crops.reduce(
    (current: { crop: Crop | null; delta: number }, crop) => {
      const delta = Math.abs(crop.width / crop.height - ar)
      return delta < current.delta ? { crop, delta } : current
    },
    { crop: null, delta: Infinity },
  ).crop
}
