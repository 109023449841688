import clsx from "classnames"
import { ContentSummaryTag } from "common/components/ContentSummaryTag"
import { Heading } from "common/components/Heading"
import { ImgixImage, ImgixImageProps } from "common/components/ImgixImage"
import { closestCrop } from "modules/editor/utils/closestCrop"
import { ImageAspectRatio } from "modules/editor/types"
import type { ContentSummaryVariantProps } from "common/components/ContentSummary/types"
import { Image, Maybe } from "common/types/graphql"

interface HeroSummaryImageProps {
  image?: Maybe<Partial<Image>>
  imageSizes?: string
  imageAspectRatio?: ImageAspectRatio
}

const GradientOverlay = () => (
  <div
    data-testid="gradient-overlay"
    className="absolute inset-0 bg-gradient-to-t from-black from-20% to-60% opacity-85"
  ></div>
)

/**
 * `HeroSummaryImage` is a functional component that renders an image with a gradient overlay, given the image prop.
 * In case the image prop is undefined, the component will return null.
 * The component uses the `closestCrop` function to determine the best crop for the image based on the provided aspect ratio.
 * If no aspect ratio is provided, the component defaults to using a widescreen crop for medium and larger screens, and a square crop for small screens.
 */
const HeroSummaryImage = ({ image, imageAspectRatio, imageSizes }: HeroSummaryImageProps) => {
  if (image?.src === undefined) {
    return null
  }

  const imageProps: ImgixImageProps = {
    ...image,
    src: image.src,
    alt: image.alt,
    sizes: imageSizes,
  }

  if (imageAspectRatio === undefined) {
    const squareCrop = closestCrop(ImageAspectRatio.Square, image.crops || [])
    const wideCrop = closestCrop(ImageAspectRatio.Widescreen, image.crops || [])

    return (
      <>
        <div className="absolute inset-0 hidden md:block">
          <ImgixImage {...imageProps} crop={wideCrop} />
        </div>
        <div className="absolute inset-0 md:hidden">
          <ImgixImage {...imageProps} crop={squareCrop} />
        </div>
        <GradientOverlay />
      </>
    )
  }

  const crop = closestCrop(imageAspectRatio, image.crops || [])

  return (
    <>
      <div className="absolute inset-0">
        <ImgixImage {...imageProps} crop={crop} />
      </div>
      <GradientOverlay />
    </>
  )
}

/**
 * The `Hero` component is a variant of the `ContentSummary` component.
 * The summary image will use the crop based on the provided `imageAspectRatio` prop.
 * If `imageAspectRatio` is not provided, the component will use a widescreen crop for medium and larger screens,
 * and a square crop for small screens.
 */
export const Hero = ({
  title,
  url,
  byline,
  hideByline,
  contentType,
  imageSizes,
  image,
  imageAspectRatio,
  tagLabel,
  tagStyle,
  anchorTarget,
  className,
}: ContentSummaryVariantProps) => (
  <a
    href={url}
    target={anchorTarget}
    className={clsx(
      "group relative flex min-h-96 w-full flex-col justify-end sm:min-h-80 lg:min-h-96 xl:min-h-120",
      className,
    )}
  >
    <HeroSummaryImage image={image} imageSizes={imageSizes} imageAspectRatio={imageAspectRatio} />
    <section className="relative flex max-w-screen-md flex-col items-start gap-4 p-4 text-white md:p-8">
      {<ContentSummaryTag contentType={contentType} tagLabel={tagLabel} tagStyle={tagStyle} />}
      <span className="drop-shadow-dark group-hover:underline">
        <Heading level={3}>
          <span dangerouslySetInnerHTML={{ __html: title || "" }} />
        </Heading>
      </span>
      {byline && !hideByline && (
        <p className="line-clamp-3 text-sm font-bold drop-shadow">{byline}</p>
      )}
    </section>
  </a>
)
